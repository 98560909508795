$(document).ready(function(){
	$('.slideshow .slidePos').cycle({
		fx:		  	 	'scrollLeft',
		sync: 			true,
		slideResize:  	true,
		timeout:		6000,
		delay:			0,
		easing: 		'linear'
	});

	$('.slideshow .slideOv').cycle({
		fx:		  	 	'scrollUp',
		sync: 			true,
		slideResize:  	true,
		timeout:		6000,
		delay:			0,
		easing: 		'linear'
	});

	$(window).scroll(function() {
		$('.slideshow .slidePos').cycle('pause');
		$('.slideshow .slideOv').cycle('pause');

		clearTimeout($.data(this, 'scrollTimer'));
		$.data(this, 'scrollTimer', setTimeout(function() {
			$('.slideshow .slidePos').cycle('resume');
			$('.slideshow .slideOv').cycle('resume');

			console.log("Haven't scrolled in 100ms!");
		}, 100));
	});

	$('.beforeAfter #slide_holder').cycle({
		fx:		   		'scrollUp',
		sync: 			true,
		slideResize:  	true,
		timeout:		6000,
		easing: 		'linear',
		pause:			true,
		next:			'#slide_holder'
	});


    window.onresize = function() {
        $('.slideshow .slidePos').cycle('pause');
        $('.slideshow .slideOv').cycle('pause');

        clearTimeout($.data(this, 'scrollTimer'));
        $.data(this, 'scrollTimer', setTimeout(function() {
            $('.slideshow .slidePos').cycle('resume');
            $('.slideshow .slideOv').cycle('resume');
        }, 300));

        var elem = document.documentElement,
            viewportWidth = window.innerWidth||elem.clientWidth;

        // If slideshow is less than viewport width
        if($('.slidePos').width() < viewportWidth) {
            // Set slides height based on slideshow classed element size
            $('.slide').height(viewportWidth * 0.410612);
            $('.slide').width(viewportWidth);
            $('.ovSlide').height(viewportWidth * 0.410612);
            $('.ovSlide').width(viewportWidth);
        }
    }
});
